import Fab from '@mui/material/Fab';
import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/Save';

import { useEffect, useState } from "react";
import { Leaf, Page } from "@cdlvsm/website.backend.lib";
import { Box, Divider, Typography } from "@mui/material";
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import { useContext } from '../../utils/use-context';
import { TinyMce } from "./TinyMce";
import { DeleteButton } from "./DeleteButton";
import { RootState } from '../../../state';
import { HistoryBar } from './utils/HistoryBar';

export interface LeafComponentProps {
  leaf: Leaf;
  state: RootState["ui"];
  loggedIn: boolean;
}

export const LeafComponent = ({ leaf, loggedIn, state }: LeafComponentProps) => {
  const chromosome = useContext();
  const [mode, setMode] = useState<"View" | "Edit">("View");
  const isEditMode = mode === "Edit";
  const path = leaf.path;
  const [html, setHtml] = useState("");
  const { page, pageHistory, pages } = state;
  useEffect(() => {
    if (leaf.pageSequenceId) {
      if (!page || page.sequenceId !== leaf.pageSequenceId || (loggedIn && (!pageHistory || !pageHistory.length || pageHistory[0].sequenceId !== leaf.pageSequenceId))) {
        setHtml("");
        chromosome.onPageLoad(leaf.pageSequenceId);
      } else {
        setHtml(page.html || "");
      }
    } else {
      setHtml("Corrupted Leaf: No page attached to this leaf.");
    }
  }, [page, pages, pageHistory, leaf.pageSequenceId]);

  useEffect(() => {
    if (!window.location.pathname.endsWith(path)) {
      chromosome.onPathSelect(path);
    }
  }, [window.location.pathname, path]);
  const onEditClick = () => {
    setMode("Edit");
  }
  const onViewClick = () => {
    setMode("View");
    chromosome.onPageContentChange(leaf, leaf.title, html);
  }
  const onDelete = () => {
    chromosome.onTreePartDelete(leaf);
  }
  const onHtmlChange = (html: string) => {
    setHtml(html);
  }
  const actionBoxstyle = {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    paddingX: "1em",
    color: 'gray'
  };
  const onPageVersionSelect = (pageId: string) => {
    chromosome.onPageVersionSelect(pageId);
  }
  const onVersionDelete = () => {
    chromosome.onPageVersionDelete(page!.id);
  }
  return (
    <div
      role="tabpanel"
    >
      {loggedIn && page && pageHistory && <HistoryBar items={pageHistory} onItemSelect={onPageVersionSelect} selectedItem={page.id} />}
      <Card sx={{
        minWidth: 275,
        boxShadow: 10,
        position: 'relative',
        border: isEditMode ? "1px dotted lightgray" : "inherit",
      }} variant="outlined">
        {loggedIn && <CardActions
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            padding: "1em"
          }}
        >
          <Box sx={actionBoxstyle}>
            <Typography component="div">Remove this page</Typography>
            <DeleteButton onDelete={onDelete} />
          </Box>
          <Divider orientation="vertical" flexItem />
          <Box sx={actionBoxstyle}>
            <Typography component="div">Remove this page version</Typography>
            <DeleteButton onDelete={onVersionDelete} />
          </Box>
          <Divider orientation="vertical" flexItem />
          <Box>
            {
              isEditMode ?
                <Box sx={actionBoxstyle}>
                  <Typography component="div" style={{ marginRight: "1em" }}>Save this page</Typography>
                  <Fab color="primary" aria-label="edit" onClick={onViewClick}>
                    <SaveIcon />
                  </Fab>
                </Box> :
                <Box sx={actionBoxstyle}>
                  <Typography component="div" style={{ marginRight: "1em" }}>Edit and create latest page from this version</Typography>
                  <Fab color="primary" aria-label="edit" onClick={onEditClick}>
                    <EditIcon />
                  </Fab>
                </Box>
            }
          </Box>
        </CardActions>}
        <CardContent>
          <Typography variant="body2" component={"span"}>
            {!isEditMode && <>
              <style>
                {`
                  .rendered-content img {
                    max-width: 100%;
                    height: auto;
                    display: block;
                  }
                `}
              </style>
              <div className="rendered-content" dangerouslySetInnerHTML={{ __html: html }} />
            </>}
            {isEditMode && <TinyMce value={html} onChange={onHtmlChange} />}
          </Typography>
          <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom component={"span"}>
            { }
          </Typography>
        </CardContent>
      </Card>
    </div>
  );
}